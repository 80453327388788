import { useEffect } from "react"
import { Divider } from "primereact/divider"
import styled from "styled-components"

import { H45 } from "components/Labels"

  export default function EmbeddedSignupForm({ className }) {
    useEffect(() => {
      const script = document.createElement("script")
      script.src = "//js.hsforms.net/forms/embed/v2.js"
      script.type = "text/javascript"
      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "39617772",
            formId: "223e0815-b372-44b1-91c9-2a4cc9a6d329",
            target: "#hubspotForm"
          })
        }
      }
      document.body.appendChild(script)
    }, [])
  
    return (
      <Holder className={className}>
        <H45>Sign up to get updates</H45>
        <Divider className="p-0 m-0 my-2" />
        <div id="hubspotForm"></div>
      </Holder>
    )
  }

const Holder = styled.div`
  min-width: 15em;
  min-height: 15em;
  height: fit-content;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 41px 0px rgba(0, 0, 0, 0.15);
  padding: 1em;
  > iframe {
    width: 100%;
    height: 900px;
    border: none;
    > body {
      background-color: #fff !important;
    }
  }
`
